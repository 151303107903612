import { getAllMutations, getAllActions } from './getAll';
import { getChildrenMutations, getChildrenActions } from './getChildren';
import { getByIdMutations, getByIdActions  } from './getById';
import { getContactsMutations, getContactsActions, getContactsGetters } from './getContacts';
import { getAddressesMutations, getAddressesActions } from './getAddresses';
import { getCustomerEmailsMutations, getCustomerEmailsActions } from './customerEmails';

// initial state
const state = () => ({
  id: undefined,
  all: {
    data: [],
    fields: [],
    percentage: 0,
    loaded: false,
    loading: false,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
  },
  customSection: {
    data: {},
    loaded: false,
    loading: false,
  },
  children: {
    data: [],
    loading: false,
    loaded: false,
    isRolledUp: false,
  },
  contacts: {
    data: [],
    loaded: false,
    loading: false,
  },
  customerEmails: {
    viewEmails: false,
    onlyUnread: false,
    summary: [],
    summaryLoading: false,
  },
  addresses: {
    data: [],
    loaded: false,
    loading: false,
  },
  loaded: false,
  loading: false,
  entity: 'customers',
  sorting: [
    {
      text: 'A-Z \'name\'',
      value: 'name:asc',
    },
    {
      text: 'Z-A \'name\'',
      value: 'name:desc',
    },
    {
      text: 'A-Z \'Account number\'',
      value: 'account_number:asc',
    },
    {
      text: 'Z-A \'Account number\'',
      value: 'account_number:desc',
    },
    {
      text: 'Date created - oldest first',
      value: 'created_date:asc',
    },
    {
      text: 'Date created - newest first',
      value: 'created_date:desc',
    },
    {
      text: 'Date updated - oldest first',
      value: 'updated_date:asc',
    },
    {
      text: 'Date updated - newest first',
      value: 'updated_date:desc',
    },
  ],
});

// getters
const getters = {
  ...getContactsGetters
};

// mutations
const mutations = {
  ...getAllMutations,
  ...getChildrenMutations,
  ...getByIdMutations,
  ...getContactsMutations,
  ...getAddressesMutations,
  ...getCustomerEmailsMutations
};

// actions
const actions = {
  ...getAllActions,
  ...getChildrenActions,
  ...getByIdActions,
  ...getContactsActions,
  ...getAddressesActions,
  ...getCustomerEmailsActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
