<template>
  <CRMCard
    :key="item.id"
    :title="highlight(item.header, item.query)"
    :chips="getChips(item)"
    :links="getCardLinks(item)"
    show-footer
    @click.stop="isShowMail ? undefined : itemClicked"
    @open="itemClicked"
    @edit="edit"
    @map-location="handleMapLocation"
    @delete="deleteItem"
  >
    <template #info>
      <TextHighlighter
        :text="item.subHeader"
        :highlight="item.query"
      />
      <div v-if="type === 'opportunity'">
        {{
          t(`DD: ${dates.format(item.decision_date)}`, 'dd_date', {
            interpolations: {
              date: dates.format(item.decision_date),
            },
          })
        }}
      </div>

      <AddressField
        v-if="type === 'customer' && item.addresses"
        :addresses="item.addresses"
        :query="item.query"
      />
    </template>
    <template #tags>
      <Tag
        :id="item.id"
        :entity="type"
      />
    </template>
    <template
      v-if="isShowMail"
      #footer
    >
      <ContactEmails
        v-model:expanded="expanded"
        class="contact-emails"
        :contact="item"
      />
    </template>
  </CRMCard>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { CRMCard, TextHighlighter } from '@sales-i/dsv3';
import { address, currency, dates, pluralise, t } from '@sales-i/utils';
import AddressField from '@/crm/components/Common/AddressField.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import { highlight } from '@/shared/utils/highlight';
import { navigateToUrl } from 'single-spa';
import { baseUrl, contactsSection, opportunitiesArea, prospectsArea } from '@/crm/router/urlBits';
import ContactEmails from '@/shared/components/Mail/ContactEmails.vue';
import useMail from '@/shared/composables/useMail';
import useMailAuth from '@/shared/composables/useMailAuth';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['itemClicked', 'deleteItem', 'searchMore']);

const store = useStore();
const { isValid } = useMailAuth();
const { isViewEmails } = useMail();

const isShowMail = computed(() => props.item.email && isValid.value && isViewEmails.value);

const journey = computed(() => store.state.journey.data);
const currencyName = computed(() => store.state.userDetails.currencyName);

const itemClicked = () => {
  emit('itemClicked', props.item);
};
const edit = (link) => {
  let { item } = link;
  if (props.type === 'contact') {
    navigateToUrl(
      `${baseUrl}/${pluralise.string(item.relationship?.record_type)}/${item.relationship?.id}/${contactsSection}/${item.id}/edit`
    );
  } else if (props.type === 'prospect') {
    navigateToUrl(`${baseUrl}/${prospectsArea}/${item.id}/edit`);
  } else if (props.type === 'opportunity') {
    navigateToUrl(`${baseUrl}/${opportunitiesArea}/${item.id}/edit`);
  }
};
const deleteItem = (link) => emit('deleteItem', link.item);
const handleMapLocation = ({ item }) => address.showOnMap(item.address);
const getChips = (item) => {
  if (props.type === 'opportunity') {
    return [
      {
        value: item.value,
        prefix: currency.symbol(currencyName),
        abbr: true,
        green: 150000,
        amber: 75000,
      },
      {
        value: item.probability,
        suffix: '%',
        green: 67,
        amber: 33,
      },
    ];
  }
  return [];
};
const getCardLinks = (item) => {
  const openObj = {
    text: t('Open'),
    item: item,
    emit: 'open',
  };
  const editObj = {
    text: t('Edit'),
    item: item,
    emit: 'edit',
  };
  const deleteObj = {
    text: t('Delete'),
    item: item,
    emit: 'delete',
  };
  if (journey.value.entity && journey.value.action) {
    return [
      {
        text: t('Select'),
        item: item,
        emit: 'open',
      },
    ];
  }
  if (props.type === 'customer') {
    return [
      address.isValid(item.address)
        ? {
          text: t('Map Location'),
          item: item,
          emit: 'map-location',
        }
        : {},
      openObj,
    ];
  }
  if (props.type === 'contact') {
    return [
      openObj,
      editObj,
      {
        text: item.mobile_phone ? t('Call') : '',
        type: 'a',
        href: `tel:${item.mobile_phone}`,
      },
      {
        text: item.email ? t('Email') : '',
        type: 'a',
        href: `mailto:${item.email}`,
      },
    ];
  }
  if (props.type === 'prospect') {
    return [openObj, editObj];
  }
  if (props.type === 'opportunity') {
    return item.status === 'open' ? [openObj, editObj, deleteObj] : [openObj, deleteObj];
  }
  if (props.type === 'interaction') {
    return [openObj, deleteObj];
  }
  return [];
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.search-bar-result {
  padding: var(--spacing-2);
  overflow: auto;
  min-height: calc(100% - 96px);
  .list-card.list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  }
}
:deep(.c-card--footer) {
  margin-top: 0!important;
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .contact-emails {
    max-width: 380px;
  }
}
</style>
