<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="notification-item">
    <BufferImage
      v-if="loading"
      color="black"
      float="center"
    />
    <CRMCard
      v-for="notification in notificationsWithLinks"
      :key="notification.id"
      :title="notification.title || categories[notification.category]"
      class="notification-card"
      :links="notification.links"
      @add-to-dashboard="addToDashboard(notification)"
      @dismiss="dismiss(notification)"
      @view-item="hideNotification(notification)"
      @download="downloadFile(notification)"
    >
      <template #info>
        <h2 class="text-4">
          {{ notification.description || capitalize(notification.category) }}
        </h2>
        <p>
          {{ formatDate(notification.created_date) }} - {{ notification.created_by }} {{ notification.type === 'SharedEnquiry' ? t('shared an enquiry with you') : t('mentioned you in a') }} {{ notification.entity_type }}
        </p>
      </template>
    </CRMCard>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { t, updateWidgetToAdd, getFileUrl } from '@sales-i/utils';
import { BufferImage, CRMCard } from '@sales-i/dsv3';
import { DateTime } from 'luxon';
import { DISMISS_MENTION, CLEAR_VISIBLE, DELETE_BY_ID } from '@/shared/store/actionType';
import { QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { alertsScope } from '@/intelligence/router/urlBits';
import { capitalize } from '@/shared/utils/strings';
import { PERFORMANCE_REGEX, INSIGHTS_REGEX, ENQUIRY_REGEX } from '@/shared/regex/reportTypes';
import { useRoute } from 'vue-router';

const props = defineProps({
  notifications: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
  },
  loaded: {
    type: Boolean,
  },
  type: {
    type: String,
    default: '',
  },
});

const store = useStore();

const categories = {
  mention: 'Mention',
  missed_opportunity: 'Missed opportunities',
  potential_opportunity: 'Potential opportunities',
  update: 'Update',
  today: 'Today',
};

const hideNotification = () => store.dispatch(`notifications/${CLEAR_VISIBLE}`);
// When the route changes, call hideNotification to close the notifications
const route = useRoute();
watch(route, hideNotification);

// Make a call to the dismiss endpoint with an id in the response body
const dismissMentions = notification => store.dispatch(`mentions/${DISMISS_MENTION}`, [notification.id]);
const dismissNotifiations = notification => store.dispatch(`notifications/${DELETE_BY_ID}`, { id: notification.id });

const dismiss = notification => {
  if (notification.type === 'SharedEnquiry') {
    dismissNotifiations(notification);
  } else {
    dismissMentions(notification);
  }
};

const getTypeAndParams = path => {
  let derivedReportType = '';

  // Check if path contains the alerts scope
  if (path.includes(alertsScope)) {
    derivedReportType = QUERY_BUILDER;
  } else if (path.includes('/performance/')) {
    // Find the segment after "performance"
    const performanceMatch = path.match(PERFORMANCE_REGEX);
    if (performanceMatch && performanceMatch[1]) {
      derivedReportType = performanceMatch[1];
    }
  } else if (path.includes('/insights/')) {
    // Extract the segment after "insights/"
    const insightsMatch = path.match(INSIGHTS_REGEX);
    if (insightsMatch) {
      // insightsMatch[2] is the second segment (if it exists)
      // So we will use insightsMatch[2] if it exists 
      // example url /intelligence/insights/products/lowest-margin-product-group?filter... => products insights insightsMatch[2] will return lowest-margin-product-group
      derivedReportType = insightsMatch[2];
    }
  } else {
    // Find the segment after "enquiry"
    const enquiryMatch = path.match(ENQUIRY_REGEX);
    if (enquiryMatch && enquiryMatch[1]) {
      derivedReportType = enquiryMatch[1];
    }
  }

  // Parse search params from the path
  const url = new URL(`${location.origin}${path}`);
  const searchParams = new URLSearchParams(url.search);
  const requestParams = {};

  searchParams.forEach((value, key) => {
    requestParams[key] = value;
  });
  return {
    reportType: derivedReportType,
    requestParams
  };
};

const addToDashboard = notification => {
  const typeAndParams = getTypeAndParams(notification.path);
  updateWidgetToAdd({
    fixed: false,
    order: 10000,
    width: 1,
    height: 1,
    reportType: typeAndParams.reportType,
    name: notification.title,
    description: notification.description,
    requestParams: typeAndParams.requestParams,
    settings: { url: notification.path },
  }, true);
};

const downloadFile = async (notification) => {
  const url = await getFileUrl(notification.path);
  window.open(url, '_blank');
};

const formatDate = date => {
  return DateTime.fromISO(date).toFormat('dd LLL yyyy : HH:mm');
};

const notificationsWithLinks = computed(() => props.notifications.map(notification => {
  let links = [];
  if (notification.type === 'SharedEnquiry') {
    links.push(
      { text: 'View', type: 'a', href: `${notification.path}` }, 
      { text: 'Add to Dashboard', id: notification.id }
    );
  }
  if (notification.category === 'MENTION') 
    links.push({ text: 'View mentions', type: 'a', href: `${notification.path}` });
  if (notification.category === 'Missed Opportunities') 
    links.push({ text: 'View missed opportunities' });
  if (notification.category === 'EXPORT') {
    links.push({ text: 'Download' });
  }
  links.push({ text: 'Dismiss', id: notification.id, });
  // add links to notification items
  return {
    ...notification, 
    links 
  };
}));
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.notification-item {
  overflow: auto;
  .notification-card {
    position: relative;
    box-shadow: none;
    border-bottom: 1px solid var(--colour-panel-g-16);
    border-radius: 0;
  }
}
.more-btn {
  display: block;
  margin: var(--spacing-1) auto;
}
.menu-button {
  position: absolute;
  top: var(--spacing-2);
  right: var(--spacing-1);
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: var(--spacing-3);
    right: var(--spacing-2);
  }
}
</style>
