<template>
  <div
    class="email-thread"
    :class="{ selected }"
  > 
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
    <div
      class="thread-header"
      role="button"
      tabindex="0"
      @click.stop="toggleConversation"
    >
      <IconBase
        class="thread-icon"
        :height="18"
        :width="18"
        :icon-color="selected ? iconColorSelected : iconColor"
        :icon-name="selected ? 'chevron-down' : 'chevron-right'"
      />
      <span class="subject">{{ modelValue?.subject }}<span
        v-if="modelValue?.message_count !== undefined"
        class="message-count"
      > ({{ modelValue?.message_count }})</span></span>
      <span
        v-if="dateToDisplay"
        class="status"
      >
        <IconBase
          :height="iconSize"
          :width="iconSize"
          :icon-color="selected ? iconColorSelected : iconColor"
          :icon-name="isReceived ? 'arrow-left' : 'arrow-right'"
        />
        {{ dateToDisplay }}
      </span>
    </div>
    <div
      v-if="selected"
      class="thread-content"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { dates } from '@sales-i/utils';
import { IconBase } from '@sales-i/dsv3';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  iconSize: {
    type: Number,
    default: 18,
  },
  iconColor: {
    type: String,
    default: 'var(--colour-utility-black)',
  },
  iconColorSelected: {
    type: String,
    default: 'var(--colour-data-puerto-rico-label)',
  },
});

const emit = defineEmits(['update:selected']);

const isReceived = computed(() => (
  props.modelValue?.latest_message_received_date > props.modelValue?.latest_message_sent_date
));

const dateToDisplay = computed(() => {
  return dates.format(isReceived.value
    ? props.modelValue?.latest_message_received_date
    : props.modelValue?.latest_message_sent_date, 'dd/MM');
});

const toggleConversation = () => {
  emit('update:selected', !props.selected);
};
</script>


<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.thread-header {
  background-color: var(--colour-data-deluge-light);
  padding: var(--spacing-half);
  border-radius: var(--border-radius-half);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-small);
}

.subject {
  flex: 1;
  text-align: left;
  font-weight: var(--font-weight-semibold);
}

.received-date {
  text-align: left;
  font-size: var(--font-size-small);
}

.status {
  display: flex;
  margin-left: auto;
  font-weight: var(--font-weight-medium);
}
</style>