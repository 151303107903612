<template>
  <div class="search-bar-results-container">
    <SearchBar
      :placeholder="placeholder"
      :filters="filters"
      :selected-filter="selectedFilter"
      @filter-selected="filterSelected"
      @keyup="debouncedOnChange"
      @search="search"
      @close-selected="close(false)"
    />
    <div
      class="container-content"
      :class="{ expanded: selectedFilter !== '' }"
    >
      <div
        v-if="selectedFilter"
        class="search-bar-results"
      >
        <SearchBarDetails
          :type="getSelectedFilter.text"
          :query="query"
          :is-global="searchBarState.getValue().isGlobal"
          :data="searchBarResults"
          :customer-name="customerDetails.name"
          @close="close(true)"
          @search-all="searchAll"
        >
          <ViewEmailsSwitches
            v-if="getSelectedFilter.text === 'Contacts'"
            :is-only-unread="isOnlyUnread"
            :is-view-emails="isViewEmails"
            :disabled="loading"
            @update:is-only-unread="setOnlyUnread"
            @update:is-view-emails="handleSetViewEmails($event, contactIds, false)"
          />
        </SearchBarDetails>
        <SearchBarResultList
          :query="query"
          :data="searchBarResults"
          :loaded="loaded"
          :loading="loading"
          :type="getSelectedFilter.value"
          :show-more-button="possiblyMoreResults"
          :recently-viewed="recentlyViewed"
          @item-clicked="itemClicked"
          @delete-item="deleteItem"
          @search-more="searchMore"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import SearchBar from '@/shared/components/SearchBar/SearchBar.vue';
import SearchBarResultList from '@/crm/components/SearchBarResult/SearchBarResultList.vue';
import SearchBarDetails from '@/shared/components/SearchBarResults/SearchBarDetails/SearchBarDetails.vue';
import ViewEmailsSwitches from '@/shared/components/Mail/ViewEmailsSwitches.vue';
import { debounce, searchBarState, showSearchBar, t } from '@sales-i/utils';

import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import {
  GET_RECENTLY_VIEWED,
  GET_SEARCH_BAR_RESULTS,
  CLEAR_SEARCH_BAR_RESULTS,
  SET_CONFIRMATION_MODAL,
  DELETE_BY_ID,
  CLEAR_ROUTE,
} from '@/shared/store/actionType';
import { pluralise } from '@sales-i/utils';
import {
  baseUrl as crmBaseUrl,
  contactsSection,
  customersArea,
  interactionsArea,
  opportunitiesArea,
  prospectsArea
} from '@/crm/router/urlBits';
import {
  customers,
  contacts,
  interactions,
  opportunities,
  prospects
} from '@/shared/store/data/policies';
import { useUsers } from '@/shared/composables/useUsers';
import { navigateToUrl } from 'single-spa';
import useMail from '@/shared/composables/useMail';
import { getContactName } from '@/crm/store/utils/mapContactsResponse';

const getResults = 20;

const store = useStore();
const { getUserById } = useUsers(store);

const props = defineProps({
  selectedFilter: {
    type: String,
    default: '',
  },
  customerId: {
    type: [String, Number],
    default: '',
  },
});

const emit = defineEmits(['close', 'filterSelected']);

const { isOnlyUnread, isViewEmails, setOnlyUnread, loadThreadsSummary, handleSetViewEmails } = useMail({ store });
const contactIds = computed(() => searchBarResultsInStore.value.map(c => c.id));

const query = ref('');
const page = ref(0);
const placeholder = ref('Search...');
const recentlyViewed = ref([]);

const customerDetails = computed(() => store.state.crm.customers.selected.data);
const journey = computed(() => store.state.journey.data);
const searchBarResultsInStore = computed(() => store.state.searchBarResults.data);
const loading = computed(() => store.state.searchBarResults.loading);
const loaded = computed(() => store.state.searchBarResults.loaded);
const permissions = computed(() => store.state.pbac.permissionsGranted);

const filters = computed(() => {
  let arr = [];
  if (permissions.value[customers] && permissions.value[customers].read) {
    arr.push({
      text: 'Customers',
      value: 'customer',
      path: 'customers',
    });
  }
  if (permissions.value[contacts] && permissions.value[contacts].read) {
    arr.push({
      text: 'Contacts',
      value: 'contact',
      path: 'contacts',
    });
  }
  if (permissions.value[prospects] && permissions.value[prospects].read) {
    arr.push({
      text: 'Prospects',
      value: 'prospect',
      path: 'prospects',
    });
  }
  if (permissions.value[opportunities] && permissions.value[opportunities].read) {
    arr.push({
      text: 'Opportunities',
      value: 'opportunity',
      path: 'opportunities',
    });
  }
  if (permissions.value[interactions] && permissions.value[interactions].read) {
    arr.push({
      text: 'Interactions',
      value: 'interaction',
      path: 'interactions',
    });
  }
  return arr;
});
const searchBarResults = computed(() => {
  if (['opportunity', 'interaction'].includes(props.selectedFilter)) {
    return searchBarResultsInStore.value.map(r => {
      const header = getUserById(r.header).display_name || t('Not allocated');
      return { ...r, header };
    });
  }
  return searchBarResultsInStore.value;
});

const debouncedOnChange = computed(() => debounce(keyup, 500));
const getSelectedFilter = computed(() => {
  const data = filters.value.find(e => e.value === props.selectedFilter);
  return data ? data : undefined;
});
const possiblyMoreResults = computed(() => {
  let count = searchBarResults.value.length;
  if (count === 0) return false;
  let multiplier = page.value + 1;
  return loading.value || count === multiplier * getResults;
});

const getSearchBarResults = params => store.dispatch(`searchBarResults/${GET_SEARCH_BAR_RESULTS}`, params);
const clearSearchBarResults = params => store.dispatch(`searchBarResults/${CLEAR_SEARCH_BAR_RESULTS}`, params);
const setConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
const deleteOpportunityById = params => store.dispatch(`crm/opportunities/${DELETE_BY_ID}`, params);
const deleteInteractionById = params => store.dispatch(`crm/interactions/${DELETE_BY_ID}`, params);
const clearJourney = params => store.dispatch(`journey/${CLEAR_ROUTE}`, params);
const getRecentlyViewed = params => store.dispatch(`searchBarResults/${GET_RECENTLY_VIEWED}`, params);

const mapRecentlyViewed = (arr, entity) => {
  return arr.map(item => {
    const keyItem = item.relationship[entity];
    let header = keyItem.name || keyItem.full_name || 'Untitled';
    let subHeader = '';
    if (['customer', 'prospect'].includes(entity)) {
      header = keyItem.name;
      subHeader = keyItem.account_number;
    }
    if (entity === 'contact') {
      header = getContactName(keyItem);
      subHeader = item.relationship.customer?.name ?? item.relationship.prospect?.name ?? '';
    }
    if (entity === 'opportunity') {
      header = getUserById(keyItem.allocated_user_id ?? '').display_name || t('Not allocated');
      subHeader = item.relationship.customer?.name ?? item.relationship.prospect?.name ?? '';
    }
    if (entity === 'interaction') {
      header = getUserById(keyItem.user_id).display_name || t('Not allocated');
      subHeader = keyItem.location;
      if (item.relationship.customer?.name || item.relationship.prospect?.name) {
        subHeader = item.relationship.customer?.name ?? item.relationship.prospect?.name ?? '';
      }
    }
    return {
      ...keyItem,
      query: '',
      header,
      subHeader,
      id: keyItem.id
    };
  });
};

const filterSelected = async item => {
  emit('filterSelected', item);
  recentlyViewed.value = [];
  const recents = await getRecentlyViewed(item.value);
  recentlyViewed.value = mapRecentlyViewed(recents ?? [], item.value);
};
const keyup = value => {
  query.value = value;
  searchResults();
};
const search = () => {
  searchResults();
};
const getPayload = (moreResults = false) => {
  if (!moreResults) {
    page.value = 0;
  }
  let { value } = getSelectedFilter.value;
  let pluralisedValue = pluralise.string(value);
  let url = !searchBarState.getValue().isGlobal && value !== 'customer' ? `customers/${props.customerId}/${pluralisedValue}` : pluralisedValue;
  let offset = page.value === 0 ? 0 : getResults * page.value;
  let payload = {
    url,
    type: value,
    parameters: {
      q: query.value,
      limit: getResults,
      offset: offset,
    },
    moreResults,
  };

  return payload;
};
const searchMore = () => {
  if (!loading.value) {
    page.value += 1;
    searchResults(true);
  }
};
const searchResults = async (moreResults = false) => {
  if (!moreResults) {
    clearSearchResults();
  }

  let queryLen = ['customer', 'prospect', 'opportunity', 'interaction'];
  if (queryLen.includes(getSelectedFilter.value?.value)) {
    queryLen = 1;
  } else {
    queryLen = 3;
  }

  if (!query.value || query.value.length < queryLen || !props.selectedFilter) {
    return;
  }
  await getSearchBarResults(getPayload(moreResults));
  loadThreadsSummary(contactIds.value);
};
const clearSearchResults = () => {
  clearSearchBarResults();
};
const close = ignore => {
  if (!props.selectedFilter || ignore) {
    emit('close');
  }
};
const itemClicked = item => {
  close(true);
  if (journey.value.entity && journey.value.action) {
    let { entity, relationEntity, action, relationId } = journey.value;
    let pushToUrl = `${crmBaseUrl}/${entity}/${item.id}`;
    if (relationEntity) {
      pushToUrl += `/${relationEntity}`;
    }
    if (action.includes('edit') && relationId) {
      pushToUrl += `/${action}/${relationId}`;
    } else {
      pushToUrl += `/${action}`;
    }
    navigateToUrl(pushToUrl);
    clearJourney();
    return;
  }
  switch (props.selectedFilter) {
  case 'customer':
    navigateToUrl(`${crmBaseUrl}/${customersArea}/${item.id}`);
    break;
  case 'contact':
    navigateToUrl(`${crmBaseUrl}/${pluralise.string(item.relationship.record_type)}/${item.relationship.id}/${contactsSection}/${item.id}`);
    break;
  case 'prospect':
    navigateToUrl(`${crmBaseUrl}/${prospectsArea}/${item.id}`);
    break;
  case 'opportunity':
    navigateToUrl(`${crmBaseUrl}/${opportunitiesArea}/${item.id}`);
    break;
  case 'interaction':
    navigateToUrl(`${crmBaseUrl}/${interactionsArea}/${item.id}`);
    break;
  }
};
const deleteItem = item => {
  let msg = '';
  let updatedMsg = '';
  let updatedFunc = () => {};
  let finishFunc = () => searchResults();
  switch (props.selectedFilter) {
  case 'opportunity':
    msg = `Do you really want to delete Opportunity #${item.id}`;
    updatedMsg = `You have deleted Opportunity #${item.id}`;
    updatedFunc = async () => {
      try {
        await deleteOpportunityById(item.id);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    };
    break;
  case 'interaction':
    msg = `Do you really want to delete Interaction #${item.id}`;
    updatedMsg = `You have deleted Interaction #${item.id}`;
    updatedFunc = async () => {
      try {
        await deleteInteractionById(item.id);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    };
    break;
  }
  setConfirmationModal({
    message: msg,
    updatedMessage: updatedMsg,
    updateFunction: updatedFunc,
    finishFunction: finishFunc,
  });
};
const searchAll = () => {
  showSearchBar();
  searchResults();
};

watch(() => props.selectedFilter, () => { searchResults(); });

onMounted(async () => { 
  searchResults(); 
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.search-bar-results-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;

  .container-content {
    position: absolute;
    width: 100%;
    height: calc(100vh - 64px);
    background-color: var(--colour-utility-white);
    overflow: auto;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .search-bar-results-container {
    position: relative;
    left: 50px;

    .container-content {
      box-shadow: 0 0 4px 1px #d5d5d5;
      width: 435px;
      top: 64px;
      right: 0;
      height: auto;
      &.expanded {
        height: calc(100vh - 64px);
      }
    }
  }
}
</style>
