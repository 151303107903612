import { computed, onUnmounted, ref, } from 'vue';
import { mailState, } from '@sales-i/utils';

export default function useMailAuth() {
  const authData = ref({});
  const authStatus = computed(() => authData.value?.status);
  const isValid = computed(() => authData.value?.status === 'VALID');
  const isInvalid = computed(() => !isValid.value);
  
  const rxm = mailState.subscribe(newMailState => {
    authData.value = newMailState.details;
  });

  onUnmounted(() => {
    rxm.unsubscribe();
  });
    
  return {
    authData,
    authStatus,
    isInvalid,
    isValid,
  };
}