<template>
  <div class="notifications-container">
    <div class="container-content">
      <NotificationBackdrop
        :mentions="mentions"
        :notifications="notifications"
        @close-notifications="closeNotifications"
      />
    </div>
  </div>
</template>

<script setup>
// Notifications
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import NotificationBackdrop from './NotificationBackdrop.vue';
import { GET_ALL, GET_MENTIONS } from '@/shared/store/actionType';

const store = useStore();
const mentions = computed(() => store.state.mentions.data);
const notifications = computed(() => store.state.notifications.data);
const getNotificationsInterval = ref();

defineProps({
  selectedFilter: {
    type: String,
    default: '',
  },
  customerId: {
    type: [String, Number],
    default: '',
  },
});

const emit = defineEmits(['close', 'filterSelected']);

onMounted(() => {
  getAll();
  getMentions();
  getNotificationsInterval.value = setInterval(() => {
    getAll();
    getMentions();
  }, 60000);
});

onUnmounted(() => {
  clearInterval(getNotificationsInterval.value);
});

const getAll = params => store.dispatch(`notifications/${GET_ALL}`, params);
const getMentions = params => store.dispatch(`mentions/${GET_MENTIONS}`, params);

function closeNotifications() {
  emit('close');
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.notifications-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;

  .container-content {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: var(--colour-utility-white);
    overflow: auto;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .notifications-container {
    position: relative;
    left: 50px;

    .container-content {
      box-shadow: 0 0 6px 1px rgba(0,0,0,0.1);
      width: 450px;
      top: -32px;
      right: 0;
    }
  }
}
</style>
