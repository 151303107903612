<template>
  <ExpandableDiv
    v-show="contactSummary != undefined"
    :expanded="expanded"
    class="mailclient"
    @click.stop
    @keypress.stop
  >
    <BufferImage
      v-if="summaryLoading"
      :size="20"
      float="center"
      class="mb-2"
    />
    <CustomCard
      v-else
      :purpose="expanded ? 'reversed' : 'transparent'"
      class="mail-card"
      :class="{ maxi: expanded, mini: !expanded }"
    >
      <div class="flex heading-wrapper">
        <div
          v-if="!expanded"
          class="emails-heading"
          tabindex="0"
          role="button"
          @keypress.enter="showThreads"
          @click.stop="showThreads"
        >
          <IconBase
            :height="20"
            :width="20"
            icon-color="var(--colour-utility-black)"
            :icon-name="isShowThreads ? 'chevron-down' : 'chevron-right'"
          />
          <!-- first we try to show total, if no summary provided we show number of threads. we show plus only if we have undefined total = no summary. -->
          {{ t('Email conversations') }} ({{
            `${total || threads?.length || '0'}${unread ? `/${unread}` : ''}${
              total === undefined && showViewMore ? '+' : ''
            }`
          }})
        </div>
        <div
          class="compose-btn-container"
          :class="{ isShowSidebar }"
        >
          <CustomButton
            v-if="isShowSidebar"
            icon-name="email"
            :label="t('Compose')"
            :purpose="expanded ? 'action' : 'text'"
            :icon-color="expanded ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
            :icon-width="defaultIconSize"
            :icon-height="defaultIconSize"
            icon-align="right"
            small
            @click.stop="openComposeView"
          />
        </div>
        <div
          v-if="expanded"
          class="header-divider"
        >
          <CustomButton
            :icon-name="isShowSidebar ? 'chevron-left' : 'chevron-right'"
            icon-color="var(--colour-utility-action)"
            icon-only
            round
            small
            purpose="neutral"
            :disabled="loading"
            :icon-width="20"
            :icon-height="20"
            @click.stop="hideSideBar"
          />
        </div>
        <h4
          v-if="expanded"
          class="emails-heading"
          tabindex="0"
          role="button"
          @keypress.enter="showThreads"
          @click.stop="showThreads"
        >
          {{ t(`Your emails with ${contact.header || contact.full_name} (${contact.email})`) }}
        </h4>
        <CustomButton
          v-if="expanded"
          purpose="text"
          :label="t('Reply')"
          icon-name="reply"
          icon-color="var(--colour-utility-action)"
          :icon-width="defaultIconSize"
          :icon-height="defaultIconSize"
          icon-align="right"
          @click.stop="replyToEmail(selectedThread)"
        />
        <IconButton
          :icon-name="expanded ? 'shrink' : 'expand'"
          :icon-size="defaultIconSize"
          icon-color="var(--colour-utility-action)"
          purpose="transparent"
          variant="round"
          :class="{ 'mr-2': expanded }"
          @click.stop="expandView"
        />
      </div>

      <!-- No threads section -->
      <div
        v-if="(isShowThreads || expanded) && !loadingThreads && (!loaded || threads?.length === 0)"
        class="threads-wrapper p-2"
        :class="{ isShowSidebar }"
      >
        <span v-if="loaded">
          {{ t('No recent email conversations') }}
        </span>
        <span v-else>
          {{ t('Could not load email conversations') }}
        </span>
        <CustomButton
          purpose="text"
          :label="t('Reload')"
          small
          @click.stop="loadThreads"
        />
      </div>

      <div
        v-show="!expanded"
        ref="miniEmailEditorTeleportArea"
        class="miniEmailEditorTeleportArea"
      />

      <!-- Email threads in view mode -->
      <div
        v-show="isShowThreads"
        class="view-mode"
      >
        <div
          class="threads-wrapper"
          :class="{ isShowSidebar }"
        >
          <BufferImage
            v-if="loadingThreads && isShowSidebar"
            :size="20"
            float="center"
            class="my-2"
          />
          <EmailThreads
            v-show="isShowSidebar"
            :threads="threads"
            :selected-id="selectedThread?.thread_id"
            :limit="limit"
            :thread-icon-size="expanded ? 24 : 18"
            @update:selected="handleThreadSelected"
            @expanded="expandView"
            @reply="replyToEmail"
            @view-more="viewMore"
            @reload="loadThreads"
          >
            <BufferImage
              v-if="loadingThread && !expanded"
              :size="20"
              float="center"
              class="my-2"
            />
            <template v-if="selectedThread">
              <div
                ref="miniEmailTeleportArea"
                class="miniEmailTeleportArea"
              />
              <span
                v-if="!loadingThread && !selectedThread?.conversation"
                class="noemails"
              >
                {{ t(`Failed to load conversation`) }}
                <CustomButton
                  purpose="text"
                  :label="t('Reload')"
                  small
                  @click.stop="handleThreadSelected(selectedThread)"
                />
              </span>
            </template>
          </EmailThreads>
        </div>

        <!-- Expanded view and edit areas -->
        <div
          v-show="expanded"
          class="maxi-content-container"
        >
          <div
            ref="maxiEmailEditorTeleportArea"
            class="maxiEmailEditorTeleportArea"
          />
          <div
            ref="maxiEmailTeleportArea"
            class="maxiEmailTeleportArea"
          />
        </div>

        <!-- TELEPORTS -->
        <!-- Email view -->
        <Teleport
          v-if="teleportTarget"
          :to="teleportTarget"
        >
          <EmailView
            v-if="!isShowEditor || originalThread"
            :loading="loadingThread"
            :model-value="selectedThread"
            @reply="replyToEmail"
          />
        </Teleport>

        <!-- Editor -->
        <Teleport
          v-if="editorTeleportTarget"
          :to="editorTeleportTarget"
        >
          <EmailEditor
            :class="{ 'm-1': expanded }"
            :contact="contact"
            :thread-to-reply="originalThread"
            @close="closeEmailEditor"
            @success="loadThreads"
          />
        </Teleport>
      </div>
    </CustomCard>
  </ExpandableDiv>
</template>

<script setup>
import { computed, ref } from 'vue';
import { t } from '@sales-i/utils';
import { CustomButton, CustomCard, IconButton, IconBase, BufferImage } from '@sales-i/dsv3';
import EmailEditor from './EmailEditor.vue';
import EmailThreads from './EmailThreads.vue';
import EmailView from './EmailView.vue';
import ExpandableDiv from '@/shared/components/ExpandableDiv.vue';
import useMail from '@/shared/composables/useMail';

const props = defineProps({
  contact: {
    type: Object,
    required: true,
  },
  expanded: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:loading', 'update:expanded']);

const { loaded, loadingThreads, threads, getMailThreads, getMailThread, summaryLoading, getContactSummary } = useMail();

const isShowEditor = ref(false);
const isShowThreads = ref(false);
const isShowSidebar = ref(true);
const loadingThread = ref(false);

const originalThread = ref(null);
const limit = ref(5);

const contactSummary = computed(() => getContactSummary(props.contact?.id));

// teleport areas
const miniEmailTeleportArea = ref(null);
const maxiEmailTeleportArea = ref(null);
const teleportTarget = computed(() => (props.expanded ? maxiEmailTeleportArea.value : miniEmailTeleportArea.value));
const miniEmailEditorTeleportArea = ref(null);
const maxiEmailEditorTeleportArea = ref(null);
const editorTeleportTarget = computed(() =>
  isShowEditor.value ? (props.expanded ? maxiEmailEditorTeleportArea.value : miniEmailEditorTeleportArea.value) : null
);

const total = computed(() => contactSummary.value?.total);
const unread = computed(() => contactSummary.value?.unread);

const selectedThread = ref(undefined);
const showViewMore = computed(() => threads.value?.length === limit.value);

const loadThreads = async () => {
  await getMailThreads({ any_email: props.contact.email, limit: limit.value });
  handleThreadSelected(selectedThread.value);
};

const defaultIconSize = computed(() => (props.expanded ? 24 : 20));

const expandView = () => {
  isShowThreads.value = true;
  if (!props.expanded && !loaded.value) loadThreads();
  emit('update:expanded', !props.expanded);
};

const openComposeView = () => {
  originalThread.value = null;
  isShowEditor.value = true;
};

const replyToEmail = thread => {
  originalThread.value = thread;
  isShowEditor.value = true;
};

const closeEmailEditor = () => {
  isShowEditor.value = false;
};

const handleThreadSelected = async thread => {
  loadingThread.value = true;
  selectedThread.value = thread?.thread_id ? await getMailThread({ thread_id: thread?.thread_id }) : undefined;
  loadingThread.value = false;
};

const viewMore = (pageSize = 3) => {
  limit.value += pageSize;
  loadThreads();
};

const showThreads = () => {
  isShowThreads.value = !isShowThreads.value;
  if (isShowThreads.value) loadThreads();
};

const hideSideBar = () => {
  isShowSidebar.value = !isShowSidebar.value;
};
</script>

<style lang="scss" scoped>
$sidebar-width: 300px;
@import '@/shared/assets/scss/_variables';
.mailclient {
  text-align: left;
}
.emails-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: var(--font-weight-semibold);
  text-align: left;
  flex: 1;
}
.noemails {
  overflow-y: auto;
  padding: var(--spacing-2);
}
.mail-card {
  box-shadow: none;
  padding: 0;
  max-height: 90vh;
}
// MINI-CLIENT
.mini {
  padding: 0;
  box-shadow: none;
  .view-mode {
    margin-top: 0;
  }
  .email-threads {
    max-height: 300px;
  }
  :deep(.scrollable) {
    max-height: 270px;
  }
  :deep(.email-thread) {
    margin-bottom: var(--spacing-half);
  }
  :deep(.email-thread.selected) {
    margin-bottom: 0;
    .thread-header {
      background-color: var(--colour-data-puerto-rico);
      color: var(--colour-data-puerto-rico-label);
    }
  }
  :deep(.email-header) {
    display: flex;
  }
  :deep(.email-details) {
    flex-grow: 1;
  }
  :deep(.email-body) {
    font-size: var(--font-size-small);
  }
}
// MAXI-CLIENT
.maxi {
  padding: 0;
  overflow: hidden;
  height: 98vh;
  .heading-wrapper {
    border-bottom: var(--shadow-colour) 1px solid;
  }
  .view-mode {
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    align-items: stretch;
    margin-top: 0;
  }
  .maxi-content-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;
    padding: var(--spacing-1);
    max-width: 100%;
    flex: 4;
  }
  .emails-heading,
  .maxiEmailTeleportArea {
    width: 100%;
  }
  .maxiEmailTeleportArea {
    justify-content: center;
    overflow-y: auto;
    max-height: 600px;
    scrollbar-width: thin;
  }
  .compose-btn-container,
  .threads-wrapper {
    min-width: 36px;
    max-width: 36px;
    overflow: hidden;
    padding: 0;
  }
  .compose-btn-container.isShowSidebar {
    padding: var(--spacing-2);
  }
  .threads-wrapper {
    flex-grow: 1;
    border-bottom-left-radius: var(--border-radius-1);
    margin: 0;
  }
  .compose-btn-container.isShowSidebar,
  .threads-wrapper.isShowSidebar {
    min-width: $sidebar-width;
    max-width: $sidebar-width;
  }
  .heading-wrapper .header-divider {
    border-right: 1px solid var(--shadow-colour);
    padding: var(--spacing-2) 0;
    margin-right: var(--spacing-2);
    margin-left: -33px;
    height: 72px;
    padding-top: 20px;
    .button {
      margin-right: -16px;
    }
  }
  :deep(.email-thread) {
    margin-bottom: 0;
  }
  :deep(.thread-header) {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--colour-data-deluge);
    padding: var(--spacing-half) var(--spacing-2);
    font-size: var(--font-size-5);
  }
  :deep(.email-thread.selected) {
    margin-bottom: 0;
    .thread-header {
      background-color: var(--colour-data-puerto-rico);
      color: var(--colour-data-puerto-rico-label);
    }
  }
  :deep(.view-more-link) {
    margin: var(--spacing-1) var(--spacing-2);
  }
  :deep(.scrollable) {
    padding: 0;
  }
  .threads-wrapper {
    align-self: stretch;
    border-right: 1px solid var(--shadow-colour);
  }
  :deep(.email-cta) {
    display: none;
  }
  :deep(.email-body) {
    padding-left: var(--spacing-2);
    border-top: 1px solid var(--shadow-colour);
  }
  :deep(.email-content) {
    border: none;
    border-radius: 0;
    padding: 0;
    padding-top: var(--spacing-1);
    margin: 0;
  }
  :deep(.email-details) {
    padding-left: var(--spacing-2);
  }
  :deep(.thread-icon) {
    display: none;
  }
  :deep(.flow) {
    display: flex;
    margin: 0;
    flex-flow: column nowrap;
  }
}
</style>
