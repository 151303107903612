import {
  SET_VIEW_EMAILS_FLAG,
  SET_ONLY_UNREAD_FLAG,
  GET_CONTACTS_SUMMARY,
  SET_CONTACTS_SUMMARY_LOADING,
  SET_CONTACTS_SUMMARY
} from '@/shared/store/actionType';

import { getThreadsSummary } from '@sales-i/utils';

// mutations
export const getCustomerEmailsMutations = {
  [SET_VIEW_EMAILS_FLAG]: (state, value) => {
    state.customerEmails.viewEmails = value;
  },
  [SET_ONLY_UNREAD_FLAG]: (state, value) => {
    state.customerEmails.onlyUnread = value;
  },
  [SET_CONTACTS_SUMMARY]: (state, value) => {
    state.customerEmails.summary = value;
  },
  [SET_CONTACTS_SUMMARY_LOADING]: (state, value) => {
    state.customerEmails.summaryLoading = value;
  }
};

// actions
export const getCustomerEmailsActions = {
  [SET_VIEW_EMAILS_FLAG]: ({ commit, }, value ) => {
    commit(SET_VIEW_EMAILS_FLAG, value);
  },
  [SET_ONLY_UNREAD_FLAG]: async ({ commit, }, value) => {
    commit(SET_ONLY_UNREAD_FLAG, value);
  },
  [GET_CONTACTS_SUMMARY]: async ({ commit, }, contact_ids) => {
    commit(SET_CONTACTS_SUMMARY_LOADING, true);
    const res = await getThreadsSummary({ contact_ids });
    commit(SET_CONTACTS_SUMMARY, res);
    commit(SET_CONTACTS_SUMMARY_LOADING, false);
    return res;
  }
};
