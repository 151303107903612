import { computed, ref, nextTick, } from 'vue';
import { getThreads, getThread } from '@sales-i/utils';
import { useStore } from 'vuex';
import { GET_CONTACTS_SUMMARY, SET_ONLY_UNREAD_FLAG, SET_VIEW_EMAILS_FLAG } from '../store/actionType';

export default function useMail({ store = useStore() } = {}) {
  const expanded = ref(false);

  const formatParticipants = (items) => items?.map(
    ({ name, email }) => name !== email ? `${name} <${email}>`:`<${email}>`
  ).join(', ');

  // View Threads, Threads Summary
  const threads = ref(null);
  const loadingThreads = ref(false);
  const loaded = computed(() => Array.isArray(threads.value));
  const isViewEmails = computed(() => store.state.crm.customers.customerEmails.viewEmails);
  const isOnlyUnread = computed(() => store.state.crm.customers.customerEmails.onlyUnread);
  const summaryLoading = computed(() => store.state.crm.customers.customerEmails.summaryLoading);
  // we show only relevant data, so we filter summary response data depending on filters set
  const filteredSummary = computed(() => isViewEmails.value && Array.isArray(store.state.crm.customers.customerEmails.summary)
    ? store.state.crm.customers.customerEmails.summary?.filter(cs => !isOnlyUnread.value || cs?.unread > 0)
    : []
  );

  const setViewEmails = (value) => store.dispatch(`crm/customers/${SET_VIEW_EMAILS_FLAG}`, value);
  const setOnlyUnread = (value) => store.dispatch(`crm/customers/${SET_ONLY_UNREAD_FLAG}`, value);
  const getThreadsSummary = (contacts) => store.dispatch(`crm/customers/${GET_CONTACTS_SUMMARY}`, contacts);

  // View Email UI helpers 
  const loadThreadsSummary = async (contacts) => { 
    if (isViewEmails.value && !summaryLoading.value) getThreadsSummary(contacts); 
  };
  // default handler for view mail switch 
  const handleSetViewEmails = async (value, contacts, reMap = true) => {
    // TODO: we should remove mapping of contacts into groups on store level and do this on component level
    // this is backward remapping of grouped contacts intpo flat array of ids
    const contact_ids = reMap 
      ? contacts?.reduce(
        (arr, contactGroup) => [...arr, ...contactGroup.items?.filter(c => c.id && c.email)?.map((contact) => contact.id)],
        []
      )
      : contacts;
    await setViewEmails(value);
    loadThreadsSummary(contact_ids);
  };

  const getMailThreads = async ({ any_email, limit = 3 }) => {
    if (!any_email || !isViewEmails.value) return;
    threads.value = [];
    await nextTick(() => loadingThreads.value = true);
    threads.value = await getThreads({ any_email, limit });
    await nextTick(() => loadingThreads.value = false);
  };

  const getMailThread = async ({ thread_id, expand_images = true, view_html = false }) => {
    const item = await getThread({ thread_id, expand_images, view_html });
    if (!item) return undefined;
    const index = (threads.value || []).findIndex((thread) => thread.thread_id === item?.thread_id);
    if (index >= 0) {
      threads.value[index] = item;
    } else {
      threads.value = [item, ...(threads.value || [])];
    }
    return item;
  };

  const getContactSummary = (contactId) => filteredSummary.value?.find(cS => cS?.contact_id == contactId);
  
  return {
    expanded,
    formatParticipants,
    // View Emails
    isViewEmails,
    isOnlyUnread,
    setViewEmails,
    handleSetViewEmails,
    setOnlyUnread,
    threads,
    loaded,
    loadingThreads,
    loadThreadsSummary,
    getMailThreads,
    getMailThread,
    // Summary
    filteredSummary,
    summaryLoading,
    getContactSummary,
    getThreadsSummary,
  };
}